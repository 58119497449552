<template>
  <div class="about">
    <h1>Broker client connecties</h1>

    <table class="table">
      <thead>
          <tr>
              <th>UUID</th>
              <th>Hostname</th>
              <th>Dealer</th>
              <th>Laatste connectie</th>
              <th>Locatie</th>
          </tr>
      </thead>
      <tbody>
          <tr v-for="client_connection of client_connections" :key="client_connection.uuid">
              <td>{{client_connection.uuid}}</td>
              <td>{{client_connection.hostname}}</td>
              <td>{{client_connection.dealer.name}}</td>
              <td>{{formatDate(client_connection.last_connection, 'dateTime')}}</td>
              <td>{{client_connection.location ? client_connection.location.name : "-"}}</td>
          </tr>
      </tbody>
  </table>
  </div>
</template>
<script>
  import integrationService from '@/services/IntegrationService';
  export default {
      name: 'ClientConnections',
      data() {
          return {
              client_connections: []
          }
      },
      methods: {
          getClientConnections(){
              integrationService.getClientConnections().then(response => {
                  this.client_connections = response.data;
              }).catch(e => this.toastError(e));
          }
      },
      mounted(){
        this.getClientConnections();
      }
  }

</script>
